import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'

import AutoSave from '../../components/AutoSave'
import { Container } from '../../components/Container'
import Documents from '../../components/documents/Documents'
import DocumentsCategories from '../../components/documents/DocumentsCategories'
import DocumentsEntities from '../../components/documents/DocumentsEntities'
import Feedback from '../../components/feedback/Feedback'
import HeadingTag from '../../components/ui/HeadingTag'
import RadioTabs from '../../components/ui/RadioTabs'
import useFilters from '../../hooks/useFilters'

const radioTabsOptions = [
  { label: 'Todos', value: 'all' },
  { label: 'Categorías', value: 'categories' },
  { label: 'Entidades', value: 'institutions' }
]

export const DocumentsPage = () => {
  const [optionTab, setOptionTap] = useState({ option: 'all', endpoint: 'documents_user.json' })
  const { onFilter, results, filters, initialFilters, status } = useFilters(optionTab.endpoint, { show_by: 'all' })

  const tabFilterOptions = {
    all: {
      endpoint: 'documents_user.json',
      component: <Documents documents={{ ...results, status }} />
    },
    categories: {
      endpoint: 'documents_user/categories.json',
      component: <DocumentsCategories categories={results.data} />
    },
    institutions: {
      endpoint: 'documents_user/institutions_user.json',
      component: <DocumentsEntities entities={results} />
    }
  }

  useEffect(() => {
    const optionType = filters['show_by']

    if (optionType) {
      setOptionTap({
        option: optionType,
        endpoint: tabFilterOptions[optionType].endpoint
      })
    }
  }, [location.search])

  return (
    <Container className="md:h-auto text-neutral-900">
      <section className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18">
        <HeadingTag title="Mis documentos" className="font-bold text-4xl" />
        <p className="mt-5 mb-10">Encuentra todos tus documentos generados con información personal.</p>
        <Form onSubmit={onFilter} initialValues={initialFilters}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AutoSave debounce={1000} save={onFilter} />
              <h2 className="text-xl font-bold pb-3.5">Mostrar por:</h2>
              <RadioTabs
                name="show_by"
                options={radioTabsOptions}
                tabDefault={initialFilters['show_by']}
                parentClassName="rounded"
              />
              <div className="pt-9">{tabFilterOptions[optionTab.option].component}</div>
            </form>
          )}
        </Form>
      </section>
      <Feedback senderType="citizen" />
    </Container>
  )
}
